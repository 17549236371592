@charset 'UTF-8';

// header
// ------

// .header-container の横幅 .container と合わせたい場合は $container-width を使用する
$header-container-width: (
  'sm': map-get( $container-width, sm ),
  'md': map-get( $container-width, md ),
  'lg': map-get( $container-width, lg )
) !default;

// .header-container の左右の padding .container と合わせたい場合は $container-padding を使用する
$header-container-padding: (
  'sm': map-get( $container-padding, sm ),
  'md': map-get( $container-padding, md ),
  'lg': map-get( $container-padding, lg )
) !default;

// headerの高さ（指定：px）
$height-header: (
  sm: 48,
  md: 52,
  lg: 70
  ) !default;

// <main> と <header> の間隔
$header-padding-bottom: (
  sm: 0,
  md: 0,
  lg: 0
) !default;

$background-header: $first-color !default; // header の background-color

@charset 'UTF-8';

// all pages
// ---------


// 全体
.base_contents_wrapper {
  position: relative;
  width: 100%;
}



// メインコンテンツ
.main_contents_wrapper {
  backface-visibility: hidden;
  //transform: translateX(0);
  -webkit-overflow-scrolling: touch;
  transition: transform $menu_transition_time;

  @include media(lg){
    margin-left: $side_navigation_width;
  }

  // メニューオープン時にコンテンツを動かす
  #{$menu_open_class} & {
    transform: translateX( $side_navigation_width );
  }
}



// TOP page
// --------

// Slider
.slider-wrapper {
  width: 100%;
  img {
    width: 100%;
  }
}


// トピックス
.top_topics {
  a {
    img {
      transition: box-shadow .3s;
    }
    &:hover img {
      box-shadow: 0 0 9px 2px #ccc;
    }
  }
}

.topics-item{
  display: flex;
  flex-direction: column;
  background: #f6efee;
  align-items: center;
  padding: 0.5em 0.5em 0 0.5em;
  span{
    display: inline-block;
    padding: .5em .5em .5em 1.25em;
    font-family: $serif;
    font-size: 1.25em;
    color: #333;
    position: relative;
    &::before{
      font-family: $icon-font-family;
      font-weight: 900;
      font-size: 0.75em;
      content: '\f138';
      color: $base-color;
      display: inline-block;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}



// 新着情報
.top_news_list {
  font-size: 1.125em;
  margin-bottom: 2rem;
  li {
    padding: 1rem 0;
    border-bottom: 1px dotted $fourth-color;
    @include media(lg) {
      padding: 1.6rem 0;
    }
    &:first-child{
      padding-top: 0;
    }
    dl {
      line-height: 1.6;
      dt {
        display: block;
        color: $base-color;
      }
    }
  }
}



// SUB pages
// ---------

// head visual
.sub-visual {
  background-image: url( '/images/sub-default.jpg' );
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  flex: none;
  padding-bottom: calc( 100% * 300 / 1920 );
  position: relative;
  @include media(lg) {
    padding-bottom: calc( 100% * 200 / 1920 );
  }
  &::before{
    background-color: rgba( $base-color, .1 );
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .sub-text {
    color: #fff;
    font-size: 1.6em;
    line-height: 1.5;
    position: absolute;
    top: 50%;
    left: 0;
    padding-left: 1em;
    transform: translate(0, -50%);
    text-align: left;
    text-shadow: 0 0 8px #333;
    @include media(lg) {
      font-size: 1.8em;
      padding-left: 3rem;
    }
  }
}

.maintenance .sub-visual{
  background-image: url( '/images/sub-maintenance.jpg' );
}

.benefit .sub-visual{
  background-image: url( '/images/sub-benefit.jpg' );
}

.proposal .sub-visual{
  background-image: url( '/images/sub-proposal.jpg' );
}

.service .sub-visual{
  background-image: url( '/images/sub-service.jpg' );
}

.achievement .sub-visual{
  background-image: url( '/images/sub-achievement.jpg' );
}

.company .sub-visual{
  background-image: url( '/images/sub-company.jpg' );
}


.msg-wrap{
  font-family: $serif;
  padding: 2em 0;
  .msg-header{
    font-size: 2em;
    padding-bottom: 0.5em;
  }
  .msg-body{
    font-size: 1.125em;
  }
}

@charset 'UTF-8';

// footer
// ------

footer {
  background: transparent;
  flex: none;
  padding-top: map-unit( $footer-padding-top, sm );
  width: 100%;
  z-index: map-unit( $z-index, footer );
  @include media(md) {
    padding-top: map-unit( $footer-padding-top, md );
  }
  @include media(lg) {
    padding-top: map-unit( $footer-padding-top, lg );
  }
}

.footer-wrap {
  background: $background-footer;
  position: relative;
  padding: 0 map-unit( $footer-container-padding, sm );
  @include media(md) {
    padding: 0 map-unit( $footer-container-padding, md );
  }
  @include media(lg) {
    padding: 0 map-unit( $footer-container-padding, lg );
  }
  @include widthWrapper( $footer-container-width, $footer-container-padding );
}

.footer-container {
  margin: 0 auto;
  @include widthContainer( $footer-container-width );
}

.footer-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em 0;

  .footer_link_list {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    li {
      &:not(:first-child) {
        margin-left: 1em;
      }
      a {
        color: $base-color;
        transition: color $transition;
        &:hover {
          color: lighten( $base-color, 20% );
        }
      }
    }
  }
}

@charset 'UTF-8';

// Copyright
// ---------
$copyright-padding-top: .7em;


.copyright-wrapper {
  background: $background-copyright;
  flex: none;
  padding: $copyright-padding-top map-unit( $copyright-container-padding, sm );
  position: relative;
  width: 100%;
  z-index: map-unit( $z-index, copyright );
  @include media(md) {
    padding: $copyright-padding-top map-unit( $copyright-container-padding, md );
  }
  @include media(lg) {
    padding: $copyright-padding-top map-unit( $copyright-container-padding, lg );
  }
  @include widthWrapper( $copyright-container-width, $copyright-container-padding );
}

.copyright-container {
  margin: 0 auto;
  @include widthContainer( $copyright-container-width );
}

.copyright {
  color: #fff;
  font-size: 85%;
  text-align: center;
}


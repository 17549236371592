@charset 'UTF-8';

$serif : 'Noto Serif JP';

.serif{
  font-family: $serif;
}

// typography
// ----------

h1,
h2,
h3,
h4,
h5,
h6 {
  font-style: normal;
  text-rendering: optimizeLegibility;
  margin-top: 0;
  margin-bottom: .4em;
  line-height: 1.4;
}

h1 {
  font-size: $h1-font-size;
}

h2 {
  font-size: $h2-font-size;
  &.first {
    //border-bottom: solid 3px darken( $background-body, 5% );
    color: $base-color;
    margin-bottom: 1em;
    text-align: center;
    & span {
      //border-bottom: solid 3px $first-color;
      //display: inline-block;
      //margin-bottom: -3px;
      //padding-bottom: 0.4em;
    }
  }
  &.second {
    color: $base-color;
    //border-bottom: 4px solid $first-color;
    //border-radius: 10px 10px 0 0;
    //padding: 0.5em 1em 0.5em 0;
    margin-bottom: 1em;
  }
}

h3 {
  font-size: $h3-font-size;
  &.first{
    background: #e5f0ef;
    border-bottom: solid 2px $first-color;
    padding: 0.4em 1em;
    margin-bottom: 1em;
    font-size: 1.3em;
  }
  &.second{
    background: lighten($second-color, 36%);
    border-bottom: solid 2px $second-color;
    padding: 0.4em 1em;
    margin-bottom: 1em;
    font-size: 1.3em;
  }
  &.third {
    position: relative;
    background: $third-color;
    border: solid 2px darken($third-color, 30%);
    border-radius: 10px;
    color: #333;
    font-size: 1.3em;
    text-shadow: 0 0 2px #fff;
    margin-bottom: 1em;
    padding: 0.4em 1em;
    z-index: -4;
  }
  &.mb_narrow {
    margin: 0 0 0.4em 0;
  }
}

h4 {
  font-size: $h4-font-size;
  &.first {
    border-bottom: 1px dotted $base-color;
    color: darken($base-color, 3%);
    margin-bottom: 1em;
    padding-bottom: .4em;
    font-size: $h5-font-size;
  }
  &.second {
    border-bottom: 1px dotted $second-color;
    color: darken($second-color, 3%);
    margin-bottom: 1em;
    padding-bottom: .4em;
    font-size: $h5-font-size;
  }
  &.third {
    color: $gray-800;
    margin-bottom: .5em;
    font-size: $h5-font-size;
  }
}

h5 {
  font-size: $h5-font-size;
  &.second {
    color: rgba($base-color , .85);
    margin-bottom: .6em;
  }
}

h6 {
  font-size: $h6-font-size;
  &.second {
    border-bottom: 1px solid #cfd8dc;
    border-left: 1px solid #cfd8dc;
    color: #424242;
    margin-bottom: .6em;
    padding-bottom: .1em;
    padding-left: .4em;
  }
}


p {
  font-size: inherit;
  letter-spacing: map-unit( $paragraph-letter-spacing, sm );
  line-height: map-unit( $paragraph-line-height, sm );
  margin-bottom: map-unit( $paragraph-margin, sm );
  text-rendering: optimizeLegibility;
  @include media(md) {
    letter-spacing: map-unit( $paragraph-letter-spacing, md );
    line-height: map-unit( $paragraph-line-height, md );
    margin-bottom: map-unit( $paragraph-margin, md );
  }
  @include media(lg) {
    letter-spacing: map-unit( $paragraph-letter-spacing, lg );
    line-height: map-unit( $paragraph-line-height, lg );
    margin-bottom: map-unit( $paragraph-margin, lg );
  }
}

// 主に paragraph を段落として使用しない場合に margin-bottom を打ち消す
.phrase {
  margin-bottom: 0 !important;
}

// イタリック
// -------
i {
  font-style: italic;
  line-height: inherit;
}

// ボールド
// ------
strong,
em,
b {
  font-style: normal;
  font-weight: bold;
  line-height: inherit;
}

// code
// ----
pre {
  background: #f2f2f2;
  margin: 1em 0;
  padding: 1em;
  white-space: pre-wrap;
  word-wrap: break-word;
}

code {
  background: transparent;
  color: #545454;
  font-family: 'Source Code Pro', monospace;
  font-size: .9375em;
  font-weight: bold;
  vertical-align: initial;
}

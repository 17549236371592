@charset 'UTF-8';

// List
// ----
ul, ol {
  list-style: none;
  line-height: inherit;
  margin: 0;
  padding: 0;

  &.li-mb {
    > li:not(:last-child) {
      margin-bottom: .8em;
    }
    ul, ol {
      //margin-top: .8em;
    }
  }

  &.indent {
    margin-left: 1em;
    ul {
      margin-top: .8em;
    }
  }
}

// ul の装飾
// -------
ul.disc {
  list-style: disc outside;
  padding-left: 1.5em;
  ul {
    list-style: circle outside;
    padding-left: 1.5em;
  }
}

ul.square {
  li {
    line-height: inherit;
    padding-left: 1.2em;
    position: relative;
    &:before {
      font-family: $icon-font-family; // Font Awesome 5 Free
      content: '\f0c8';
      color: $base-color;
      font-weight: 900;
      margin-right: .4em;
      position: absolute;
      left: 0;
    }
  }
}

ul.circle {
  li {
    line-height: inherit;
    padding-left: 1.2em;
    position: relative;
    &:before {
      font-family: $icon-font-family; // Font Awesome 5 Free
      content: '\f111';
      color: $base-color;
      font-weight: 900;
      margin-right: .4em;
      position: absolute;
      left: 0;
    }
  }
}

.list-flex {
  display: flex;
  flex-flow: row wrap;
  li:not(:first-child) {
    margin-left: .5em;
  }
}

// ol
// --
ol.num {
  list-style: decimal outside;
  padding-left: 1em;
  ol {
    list-style: decimal outside;
    padding-left: 1em;
  }
}

// dl
// --
dl {
  dt {
    font-weight: bold;
    &:not(:first-child) {
      margin-top: 1em; }
  }
  dd {
    line-height: 1.4;
  }
}

dl.dl-horizon-left {
  //margin: 0 auto;
  & dt, dd {
    line-height: 1.4;
  }
  & dt {
    float: none;
    margin-bottom: .25em;
    @include media(md) {
      width: 10em;
      float: left;
      clear: left;
      margin-top: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 1.5;
    }
  }
  & dd {
    margin-left: 0;
    margin-bottom: .25em;
    line-height: 1.5;
    @include media(md) {
      margin-left: 11.25em;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
  &::after {
    content: "";
    display: block;
    clear: both;
  }
  
  @for $i from 1 through 10 {
    &.dt#{$i * 1}{
      & dt{
        width: #{$i * 1}em !important;
      }
      & dd{
        padding-left: #{$i * 1}em;
      }
    }
  }
  @for $i from 3 through 10 {
    &.dt#{$i * 4}{
      & dt{
        width: #{$i * 4}em !important;
      }
      & dd{
        padding-left: #{$i * 4}em;
      }
    }
  }
}
@charset 'UTF-8';

// header
// ------

header {
  background: transparent;
  padding-bottom: map-unit( $header-padding-bottom, sm );
  position: fixed;
  width: 100%;
  z-index: map-unit($z-index, header);
  @include media(md) {
    padding-bottom: map-unit( $header-padding-bottom, md );
  }
  @include media(lg) {
    padding-bottom: map-unit( $header-padding-bottom, lg );
    position: static;
  }
}

.header-wrap {
  background: $background-header;
  height: map-unit( $height-header, sm, rem );
  position: relative;
  padding: 0;
  @include media(md) {
    height: map-unit( $height-header, md, rem );
    padding: 0;
  }
  @include media(lg) {
    height: map-unit( $height-header, lg, rem );
    padding: 0;
  }
}


.header-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate( -50%, -50% );
  @include media(lg) {
    left: auto;
    right: 1em;
    transform: translate( 0, -50% );
  }
  img {
    width: 166px;
    height: auto;
    @include media(md) {
      width: 166px;
    }
    @include media(lg) {
      width: 244px;
    }
  }
}



.phone-trigger {
  border-left: solid 1px #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: map-unit( $height-header, sm, rem );
  height: map-unit( $height-header, sm, rem );
  padding: 1rem;

  span {
    color: #fff;
    display: block;
    font-size: 2em;
  }

  @include media(md) {
    width: map-unit( $height-header, md, rem );
    height: map-unit( $height-header, md, rem );
  }
  @include media(lg) {
    display: none;
  }
}
